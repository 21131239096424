import enterView from 'enter-view';

export default {
    init() {

    },
    finalize() {
        enterView({
            selector: '.uhood-campaign',
            enter: el => {
                const total = parseInt(el.dataset.total, 10);
                const current = parseInt(el.dataset.current, 10);
                const percentage = (current * 100) / total;

                const fill = el.querySelector('.uhood-campaign-progress-bar-fill');
                fill.style.width = percentage + '%';
            },
            once: true,
            offset: 0.7
        });
    }
}

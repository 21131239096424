import CountUp from 'countup';
import enterView from 'enter-view';
export default {
  init() {

  },
  finalize() {
    const counters = [
      {
        id: 'counter-descargas',
        counter: null,
        decimals: 0
      },
      {
        id: 'counter-causas',
        counter: null,
        decimals: 0
      },
      {
        id: 'counter-euros',
        counter: null,
        decimals: 1
      }
    ];

    for (let i = 0; i < counters.length; i++) {
      const counterData = counters[i];
      const amount = counterData.decimals > 0 ? parseFloat(document.getElementById(counterData.id).dataset.formatted) :
          parseInt(document.getElementById(counterData.id).dataset.formatted, 10);

      const currentCounter = new CountUp(counterData.id, 0, amount, counterData.decimals);
      if (currentCounter.error) {
        console.error(currentCounter.error);
      }

      counterData.counter = currentCounter;
    }

    enterView({
      selector: '.uhood-stats-counter-wrapper',
      enter: (el) => {
        const currentId = el.querySelector('.uhood-stats-counter-amount').id;
        counters.forEach(counterData => {
          if (counterData.id === currentId) {
            counterData.counter.start();
          }
        });
      },
      once: true
    });

    enterView({
      selector: '.uhood-features-mockup',
      enter: el => {
        el.classList.add('fade-in-left');
      }
    });

    enterView({
      selector: '.uhood-features-list',
      enter: (el) => {

        el.querySelector('h2').classList.add('fade-in-right');
        let currentTimeout = 0;
        el.querySelectorAll('dl > *').forEach(item => {
          currentTimeout += 100;
          setTimeout(() => item.classList.add('fade-in-right'), currentTimeout);
        });
      },
      once: true,
      offset: 0.2
    });
  }
}
